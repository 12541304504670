.label {
  @apply flex;
  @apply relative;
  @apply flex-col;
  @apply w-full;
  @apply bg-transparent;
  -webkit-tap-highlight-color: transparent;

  .icon {
    @apply absolute;
    @apply top-0;
    @apply right-0;
    @apply px-4;
    padding-top: rem(10);
    font-size: rem(26);

    svg {
      @apply fill-current;
    }
  }

  .error {
    color: #ff5e55;

    @apply text-xs;
    min-height: rem(18);
  }

  &:focus-within {
    span.inner-label {
      @apply text-teal-dark;
    }

    div.select-arrow {
      @apply text-teal-dark;
    }
  }

  &:hover {
    .select-arrow,
    .inner-label {
      @apply text-black/75;
    }

    .select,
    .input {
      @apply border-black/75;
      @apply cursor-pointer;
    }
  }

  .inner-label {
    @apply absolute;
    @apply text-xs;
    @apply top-0;
    @apply left-0;
    @apply -mt-2;
    @apply ml-4;
    @apply text-gray-600;
    @apply px-1;
    @apply capitalize;
    @apply z-10;
    @apply transition-colors;
    @apply duration-500;
    @apply ease-in-out;

    &:after {
      content: '';
      position: absolute;
      top: 0.5625rem;
      @apply border-b-2;
      @apply border-white;
      @apply w-full;
      @apply left-0;
      transform: translateY(-50%);
      z-index: -10;
    }
  }

  .input-field-error {
    border-color: #ff5e55 !important;
  }

  .text-coral-normal a {
    color: #ff5e55 !important; // Also apply color to anchor tags
  }

  .select,
  .input {
    @apply w-full;
    @apply bg-white;
    @apply text-black;
    @apply rounded-lg;
    @apply border;
    @apply border-black/50;
    @apply px-4;
    @apply py-3;
    @apply transition-colors;
    @apply duration-500;
    @apply ease-in-out;

    &:focus {
      @apply outline-none;
      @apply border-teal-dark/50;
    }

    &::placeholder,
    &::-webkit-input-placeholder {
      @apply text-sm;
    }
  }

  .select {
    @apply appearance-none;
    @apply pr-12;
  }

  .select-arrow {
    @apply duration-75;
    @apply transition-colors;
    @apply duration-500;
    @apply ease-in-out;
    @apply pointer-events-none;
    @apply absolute;
    @apply top-0;
    @apply right-0;
    @apply flex;
    @apply items-center;
    padding-top: rem(10);
    @apply px-4;
    @apply text-gray-600;
    @apply rounded-tr-lg;
    @apply rounded-br-lg;
  }

  /* .label.white */
  &.white {
    .select,
    .input {
      @apply w-full;
      @apply bg-transparent;
      @apply text-white;
      @apply border-white;

      &:focus {
        @apply outline-none;
        @apply border-white/75;
      }

      &::placeholder,
      &::-webkit-input-placeholder {
        @apply text-sm;
        @apply text-white;
      }
    }

    .select {
      @apply bg-black;
      @apply text-white;

      &:focus {
        @apply outline-none;
        @apply border-teal-dark;
      }
    }

    .select-arrow {
      @apply text-gray-100;
    }
  }

  /* .label.black */
  /* TODO: add input and other colors */
  &.black {
    .select {
      @apply bg-black;
      @apply text-white;

      &:focus {
        @apply outline-none;
        @apply border-teal-dark;
      }
    }

    .select-arrow {
      @apply text-gray-100;
    }
  }

  .checkbox {
    position: relative;
    flex: 0 0 rem(22);
    width: rem(22);
    height: rem(22);
    @apply bg-white;
    @apply border;
    @apply border-gray-600;
    @apply mr-2;
    @apply cursor-pointer;
  }

  .checkbox-error {
    position: relative;
    flex: 0 0 rem(22);
    width: rem(22);
    height: rem(22);
    @apply bg-white;
    @apply border;
    @apply border-coral-normal;
    border-color: #ff5e55 !important;
    @apply mr-2;
    @apply cursor-pointer;
  }

  input[type='checkbox'] {
    &:checked + .checkbox {
      @apply bg-black;

      &:after {
        content: '';
        @apply absolute;
        top: rem(4);
        left: rem(4);
        @apply bottom-0;
        @apply border-l-2;
        @apply border-b-2;
        @apply border-white;
        transform: rotate(-51deg);
        width: rem(12);
        height: rem(6);
      }
    }

    &:disabled + .checkbox {
      @apply bg-gray-300;
      @apply border-gray-300;
    }
  }
}

.dropdown {
  .input-field-error {
    border-color: #ff5e55 !important;
    @apply mb-4;
  }
}
