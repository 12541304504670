// TODO: Delete if not used
.recent-customer-sales-item {
  position: relative;
  max-height: rem(113);

  @media (min-width: theme('screens.md')) {
    max-height: rem(136);
  }

  .wp-block-group__inner-container {
    width: 100%;
  }

  .wp-block-image {
    margin-bottom: 0;

    &:after {
      content: 'SOLD';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      background-color: $coral-normal;
      color: $white;
      font-size: rem(10);
      font-weight: 700;
      line-height: rem(11);
      padding: rem(6) rem(12);
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .drive-image {
    margin-bottom: 0;
    width: 50%;
    height: 100%;
    float: left;

    &:after {
      content: 'SOLD';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      background-color: $coral-normal;
      color: $white;
      font-size: rem(10);
      font-weight: 700;
      line-height: rem(11);
      padding: 0.375rem 1.2rem 0.375rem 0.75rem;
      clip-path: polygon(100% 0, 100% 0, 70% 100%, 0 100%, 0 0);
    }

    div {
      height: 100%;
      width: rem(156);

      @media (min-width: theme('screens.md')) {
        width: 100%;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .recent-customer-sales-title {
    margin-bottom: rem(1);
    word-break: break-all;
    line-height: rem(24);

    @media (min-width: theme('screens.md')) {
      margin-bottom: rem(8);
    }
  }

  .recent-customer-sales-item-pricing-column {
    display: block;
    margin-bottom: rem(1);

    @media (min-width: theme('screens.md')) {
      margin-bottom: rem(12);
    }

    > .wp-block-column {
      display: inline-block;
      margin-left: 0 !important;

      &:first-child {
        margin-right: rem(8);
      }

      p {
        margin: 0;
      }
    }
  }

  .recent-customer-sales-item-location {
    background-image: url('/images/pin.svg');
    background-repeat: no-repeat;
    margin-top: 0;
    margin-bottom: 0 !important;
    font-size: rem(13);
    line-height: rem(18);

    @media (min-width: theme('screens.md')) {
      margin-top: rem(8);
    }
  }
}

@media (max-width: theme('screens.md')) {
  .recent-customer-sales-row {
    margin-bottom: 0 !important;
  }
}

.recent-customer-sales-group {
  margin: rem(40) auto;

  .recent-customer-sales-columns.wp-block-columns {
    width: rem(320);
    margin: 0 auto;
    display: grid;
    gap: 1rem;

    > .wp-block-column {
      margin-left: 0 !important;
    }
  }

  @media (min-width: theme('screens.md')) {
    .recent-customer-sales-columns.wp-block-columns {
      width: 100%;
      margin-bottom: rem(28);
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  @media (min-width: theme('screens.lg')) {
    .recent-customer-sales-columns.wp-block-columns {
      grid-template-columns: repeat(3, minmax(0, 1fr));

      > .wp-block-column:first-child {
        margin-bottom: rem(50);
      }
    }
  }
}
