.m-react-select {
  @apply w-full;
  @apply font-semibold;

  .m-react-select {
    &__control {
      background: none;
      @apply border-white;
      @apply rounded-lg;
      @apply py-2;
      @apply px-1;
      @apply my-0;
      @apply mx-auto;
    }

    &__placeholder {
      @apply text-white;
    }

    &__dropdown-indicator {
      @apply text-white;

      > :global(.drive-icon) {
        @apply w-6;
        @apply h-6;
      }
    }

    &__option {
      @apply text-black;
      @apply px-4;
      @apply py-3;
    }

    &__menu {
      height: 25rem;
      @apply border-none;
      @apply rounded-none;
      box-sizing: unset;
      @apply shadow-none;
      @apply mb-0;
      @apply -mt-1;
      @apply overflow-x-hidden;
    }

    &__menu-list {
      @apply h-full;
      @apply max-h-screen;
      @apply py-0;
    }

    &__group-heading {
      text-transform: unset;
      @apply pl-4;
    }

    &__single-value {
      @apply text-white;
    }

    &__input {
      @apply text-white;
    }

    /* Styles for scrollbar */

    /* width */
    &__menu-list::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &__menu-list::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &__menu-list::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &__menu-list::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    /* Styles for scrollbar */
  }
}
