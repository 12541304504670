@use '/styles/mixin/fonts.module.scss' as *;
.bp-fuel-consumption {
  &.header {
    .drive-heading-lists {
      @apply w-full;
      @apply mb-4;
      .title {
        @apply pr-4;
        > h3 {
          @include font-subtext('desktop', 'large');
          @media (max-width: 767px) {
            @include font-subtext('mobile', 'large');
          }
        }
      }
    }
    @media (min-width: 600px) and (max-width: 781px) {
      // gutenburg style library adds important here so overwriting
      .wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column:not(:only-child) {
        flex-basis: 60% !important;
        &.image-container {
          // gutenburg style library adds important here so overwriting
          flex-basis: 30% !important;
          flex-grow: 1;
        }
      }
    }
    .wp-block-columns > .wp-block-column {
      @apply flex;
      @apply flex-row;
      @apply justify-items-center;
      @apply justify-start;
      @apply items-center;
      flex-basis: 80% !important;
      @media (max-width: 767px) {
        // gutenburg style library adds important here so overwriting
        flex-basis: 70% !important;
      }
      &.image-container {
        // gutenburg style library adds important here so overwriting
        flex-basis: 20% !important;
        @apply pr-0;
        @apply mb-0;
        @media (max-width: 767px) {
          // gutenburg style library adds important here so overwriting
          flex-basis: 30% !important;
        }
        @apply justify-end;
        .wp-block-image {
          @apply m-0;
        }
        img {
          max-width: 125px;
          @apply h-auto;
          @apply w-full;
        }
      }
    }
  }
  table tr td {
    @apply px-4;
  }

  &.content-lists {
    > .drive-heading-lists {
      @apply m-0;
      figure.wp-block-table {
        margin-bottom: 18px;
      }
    }

    .wp-block-table table {
      thead {
        @apply border-0;
        @apply bg-teal-dark;
        @apply text-white;
        tr td {
          @include font-subtext('desktop', 'medium');
        }
      }
      tr {
        @apply h-10;
        border: 0;
        @media (max-width: 767px) {
          @apply h-16;
        }
        > td {
          border: 0;
        }
      }
      tbody {
        tr {
          &:nth-child(even) {
            @apply bg-gray-100;
          }
        }
      }
    }
  }
  &.bp-external-link {
    @apply text-right;
    a {
      @apply text-teal-dark;
      @include font-subtext('desktop', 'medium');
    }
    svg {
      fill: $teal-dark;
    }
  }
}
