@use 'sass:math';
/* Convert PX to REM global function */
@function rem($px, $basepx: 16) {
  @return math.div($px, $basepx) + 0rem;
}

/* Tailwinds */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Customized */
@import './fonts.scss';
@import '1-settings/colors';
@import './theme.scss';
@import './global.scss';
// Add wp-block-cover to fix SassError: "Error: Specified z-index `.wp-block-cover__image-background` does not exist in the mapping"
.wp-block-cover-image picture.wp-block-cover__image-background,
.wp-block-cover picture.wp-block-cover__image-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
.wp-block-cover-image picture.wp-block-cover__image-background > img,
.wp-block-cover picture.wp-block-cover__image-background > img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  max-width: none;
  max-height: none;
  -o-object-fit: cover;
  object-fit: cover;
  outline: none;
  border: none;
  box-shadow: none;
}

// Commented the local font as we are using preload fonts from cdn
// @import "./fonts/index.scss";

@import '2-gutenberg/gutenberg';
/* Gutenberg default styles. (KEEP THESE HERE)  */
@import '~@wordpress/base-styles/colors';
@import '~@wordpress/base-styles/variables';
@import '~@wordpress/base-styles/mixins';
@import '~@wordpress/base-styles/breakpoints';
@import '~@wordpress/base-styles/animations';
@import '~@wordpress/base-styles/z-index';
@import '~@wordpress/block-library/src/style.scss';
@import '~@wordpress/block-library/src/theme.scss';

/* Slick carousel default styles. */
$slick-font-path: '~slick-carousel/slick/fonts/';
$slick-loader-path: '~slick-carousel/slick/';

@import '~slick-carousel/slick/slick.scss';
@import '~slick-carousel/slick/slick-theme.scss';
