@use '/styles/mixin/fonts.module.scss' as *;

// multi-review.scss
.wp-block-drive-blocks-standfirst,
.wp-block-columns.drive-heading-lists {
  margin-bottom: 2.75rem;
}

#vehicle-enquiry-form {
  p {
    @include font-heading('mobile', 'h3');
    @apply font-semibold;
    @screen md {
      @include font-heading('desktop', 'h3');
      @apply font-semibold;
    }
  }
  .dropdown .item div {
    @apply p-14px;
  }
  .error {
    p {
      @apply pt-2;
      @include font-caption('mobile', 'bold');
      @screen md {
        @include font-caption('desktop', 'bold');
      }
    }
  }
}

.core-group-4 .wp-block-group__inner-container {
  max-width: rem(1500);
  margin: 0 auto;
}

.core-group-2 .wp-block-group {
  max-width: 716px;
}

.btn-wrapper {
  @apply w-full;
  @apply flex;

  @media (max-width: 767px) {
    @apply justify-center;

    .btn {
      width: 100%;
      @apply text-teal-dark;
    }
  }

  @screen md {
    margin-top: rem(28);
    @apply justify-end;

    .btn {
      width: 30%;
      @apply text-teal-dark;
    }
  }
  .error-btn {
    @apply text-white;
    @apply bg-coral-normal;
    border: unset;

    &:hover {
      @apply bg-coral-normal;
    }
  }
}

// search-result.scss
.category {
  @apply absolute;
  @apply left-0;
  @apply text-white;
  @apply uppercase;
  @apply bg-black;
  @apply leading-4;
  height: rem(20);
  line-height: rem(20);
  padding-left: rem(9);
  padding-right: rem(4);

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: rem(16) solid $black;
    border-right: rem(16) solid transparent;
    border-bottom: rem(20) solid transparent;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin-left: 100%;
  }
}

// POC Font Classes

/** ---------- Wordpress Blocks -------------**/
.single-news,
[class^='reviewSingle_drive-review-single__articles'],
[class^='default_drive-default-wrapper'],
[class^='main-content'] {
  [class^='blocks_drive-block'],
  [class^='reviewMultiple_drive-block__wrapper'] {
    span:not([class*='featuredSideImage_drive-featured-post-block__side-image__button'])
      span:not([class*='model-showroom-cta_d-container__price']),
    td,
    tr {
      @include font-text('mobile', 'large');

      p {
        @include font-text('mobile', 'large');

        @screen md {
          @include font-text('desktop', 'large');
        }
      }
    }
    strong {
      @apply font-semibold;
    }

    h2:not([class^='featuredSideImage_drive-featured-post-block__side-image__title']) {
      @include font-heading('mobile', 'h3');
      @apply font-medium;

      @screen md {
        @include font-heading('desktop', 'h3');
      }
    }

    // Override the link text styles for snapshot block
    [class*='snapshot_d-container__title'] {
      @include font-heading('mobile', 'h3');
      @screen md {
        @include font-heading('desktop', 'h3');
      }
    }
    [class*='model-showroom-cta_d-container__link-wrapper'] {
      span {
        @include font-subtext('mobile', 'large');
        @screen md {
          @include font-subtext('desktop', 'large');
        }
      }
    }
    [class*='snapshot_d-container__rating-out-of'] {
      @include font-text('desktop', 'large');
      @screen md {
        @include font-pullquote('mobile');
      }
    }

    // Overwrite font for lightbox button
    [class^='lightbox-btn'] {
      @include font-caption('mobile', 'bold');
      @screen md {
        @include font-button;
      }
      span {
        @include font-caption('mobile', 'bold');
        @screen md {
          @include font-button;
        }
      }
    }

    // Override the MarketplacecListingsBlock link style
    [class^='dml-slider_drive-articles__glide-slides'] {
      span {
        @include font-subtext('desktop', 'medium');
      }
      [class*='priceRatingLabel_drive-price-rating-label__text'] {
        @apply text-13px;
      }
    }
    [class^='marketplace-listing-card_drive-model-card__price'] {
      .wrapper {
        span {
          @include font-heading('desktop', 'h3');
          @apply text-gray-600;
        }
      }
      span {
        @include font-caption('desktop', 'normal');
        @apply text-gray-500;
      }
    }

    .drive-enquiry-form {
      .tc {
        @include font-caption('mobile', 'normal');
        @screen md {
          @include font-caption('desktop', 'normal');
        }
      }
    }

    .stats-heading {
      @include font-heading('mobile', 'h4');
      @screen md {
        @include font-subtext('desktop', 'large');
      }
    }
  }

  #h-what-we-love,
  #h-what-we-don-t {
    .stats-heading {
      @include font-heading('mobile', 'h4');

      @screen md {
        @include font-subtext('desktop', 'large');
      }
    }
  }
}

.wp-block-drive-blocks-standfirst {
  p,
  span {
    @include font-standfirst('mobile');
    @screen md {
      @include font-standfirst('desktop');
    }
  }
}

.dcoty-paragraph-title {
  @include font-heading('mobile', 'h2');
  @apply w-screen;
  @apply left-1/2;
  @apply -translate-x-1/2;
  @apply relative;
  @apply px-6;
  @apply my-0;
  @apply max-w-[805px];

  @screen md {
    @apply px-8;
  }

  @screen lg {
    @include font-heading('desktop', 'h2');
    @apply px-0;
  }
}

.dcoty-paragraph {
  @include font-text('desktop', 'medium');
  @apply w-screen;
  @apply left-1/2;
  @apply -translate-x-1/2;
  @apply relative;
  @apply px-6;
  @apply mb-8;
  @apply mt-6;
  @apply mx-0 #{!important};

  @screen md {
    @apply px-8;
  }

  @screen lg {
    @include font-text('desktop', 'large');
    @apply px-0;
    @apply mb-16;
  }

  mark.has-inline-color.has-blue-dark-color {
    @include font-subtext('mobile', 'large');
    @screen lg {
      @include font-subtext('desktop', 'large');
    }
  }
}

.wp-block-drive-blocks-heading {
  span {
    @include font-subtext('mobile', 'h4');

    @screen md {
      @include font-subtext('desktop', 'large');
    }
  }
}

.drive-heading-lists__left-col {
  li {
    @include font-text('mobile', 'large');

    @screen md {
      @include font-text('desktop', 'medium');
    }
  }
}

.core-heading-2 {
  #h-sell-my-car {
    padding-top: 4rem;
    @apply mt-0;
    margin-bottom: 2.063rem;
    @screen md {
      @apply pt-0;
      @apply mb-4;
      margin-top: 3.25rem;
    }
  }
}

.core-group-3 {
  #sell-your-car {
    margin-bottom: 2.688rem; // 43px
    @include font-heading('mobile', 'h2');
    @screen md {
      @apply mb-0;
      @include font-heading('desktop', 'h2');
    }
  }

  p {
    @include font-text('mobile', 'large');
    @screen md {
      @include font-text('desktop', 'large');
    }
  }

  .drive-md-one-column {
    @screen md {
      @apply mt-6;
    }
  }

  .wp-block-columns {
    p {
      @apply my-0;
      @include font-text('desktop', 'large');
      @screen md {
        @apply mb-4;
      }
      strong {
        @include font-subtext('mobile', 'large');
        @screen md {
          @include font-subtext('desktop', 'large');
        }
      }
    }

    .wp-block-column {
      margin-bottom: 2.688rem;
      @screen md {
        @apply mb-0;
      }
    }
  }
}

.core-group-5 {
  @apply w-full-screen;
  margin-left: -1.25rem;
  @screen md {
    @apply ml-0;
  }

  .wp-block-group {
    .drive-image-block__inner-wrapper {
      margin-bottom: 0.938rem; // 15px
    }
    h5 {
      @apply mt-0;
      margin-bottom: 0.813rem; // 13px
      @include font-heading('mobile', 'h4');
      @screen md {
        @include font-subtext('desktop', 'large');
      }
    }
    p {
      @apply mt-0;
      margin-bottom: 2.438rem;
      @apply p-0;
      width: 19.5rem;
      @include font-text('mobile', 'medium');
      @screen md {
        width: 25.375rem;
        @include font-text('desktop', 'medium');
      }
    }
  }

  [class^='defaultBlock_drive-default-block'] {
    #h-sell-car-fast {
      height: 52.5rem; // 840px
      padding-top: 2.688rem; // 43px
      padding-bottom: 1.813rem;
      @screen md {
        padding-top: 3.875rem; // 62px
        padding-bottom: 3.563rem; // 57px
        height: 30rem; // 480px
      }
      .wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column:not(:first-child) {
        @screen md {
          margin-left: 7.313rem;
        }
      }
    }

    .wp-block-columns {
      @apply mb-0;
      @screen md {
        @apply justify-center;
        padding-left: 16rem;
        padding-right: 16.063rem;
        margin-bottom: 1.438rem;
      }
      .wp-block-column {
        @screen md {
          max-width: 25.375rem;
        }
      }
    }
  }
}

/** ---------- For disclaimer class coming from wordpress -------------**/
.disclaimer-text {
  @apply text-gray-400;
  @include font-text('mobile', 'medium');
  @screen md {
    @include font-text('desktop', 'small');
  }
}

.disclaimer-link {
  @apply mb-5;
  @apply mt-5;
  @apply pt-8;
  @apply border-t;
  @apply border-gray-300;
  @screen xs {
    @apply pl-4;
  }
  > a {
    @include font-subtext('mobile', 'large');
    @apply no-underline #{!important};
    @apply text-gray-400;
    @apply cursor-pointer;
    &:hover {
      @apply text-gray-400;
    }
    @screen md {
      @include font-subtext('desktop', 'medium');
    }
  }
}
