@use '/styles/mixin/fonts.module.scss' as *;
@import './variables.scss';
@import './3-components/index.scss';
@import './5-pages/index.scss';

html,
body {
  @apply font-primary;
}

/** Default css for html elements. This styles will be applied if components do not state any styles for elements */
@layer base {
  html {
    overflow-x: hidden;
  }
  body {
    width: 100%;
  }

  a {
    @apply text-blue-dark;
    @apply transition-colors;
    @apply duration-500;
    @apply cursor-pointer;
  }

  a:hover {
    @apply text-blue-normal;
  }

  dl,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  p,
  pre,
  blockquote {
    @apply my-2;
  }

  /** Heading tags without any specific css will fallback to last defined tag css */
  h7,
  h6,
  h5,
  h4 {
    @include font-heading('mobile', 'h4');
    @screen md {
      @include font-heading('desktop', 'h4');
    }
  }

  h3 {
    @include font-heading('mobile', 'h3');
    @screen md {
      @include font-heading('desktop', 'h3');
    }
  }

  h2 {
    @include font-heading('mobile', 'h2');
    @screen md {
      @include font-heading('desktop', 'h2');
    }
  }

  h1 {
    @include font-heading('mobile', 'h1');
    @apply my-3;
    @screen md {
      @include font-heading('desktop', 'h1');
    }
  }

  p {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    letter-spacing: 0.00625em;
    line-height: 26px;
    @apply font-normal;
  }

  b,
  strong {
    @apply font-bold;
  }

  button:focus {
    outline: none;
    outline: none;
  }

  input {
    /** Sets placeholder fonts to closest font styles */
    &::placeholder,
    &::-webkit-input-placeholder {
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
    }
  }
}

@layer utilities {
  .truncate-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
  .truncate-2 {
    /* needs line-height and height */
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
  .truncate-3 {
    /* needs line-height and height */
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
  .truncate-4 {
    /* needs line-height and height */
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
  .truncate-8 {
    /* needs line-height and height */
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
}

.disable-scroll {
  @apply overflow-hidden;
}

.dropdown.links {
  @apply mb-4;

  .item {
    @apply pb-2;
    @apply border-b;

    a {
      @apply w-full;
      @apply float-none;
      @apply justify-between;
    }

    &:first-of-type {
      @apply mt-1;
    }
  }
}
