.drive-heading-lists {
  &__col-2 {
    &.wp-block-column:not(:first-child) {
      margin-left: 0;
    }
  }

  ul {
    list-style: none;
    @apply pl-2;
  }

  ul li {
    @apply text-base;
    @apply leading-6;
    @apply font-normal;
    @apply mb-3;
    @apply flex;

    &:before {
      content: '-';
      @apply text-gray-500;
      @apply mr-6;
    }
  }

  ul.drive-heading-lists__left-col li {
    &:before {
      @apply text-blue-normal;
    }
  }

  .drive-icon-heading h6 {
    padding-top: 3px;
  }

  .drive-icon-heading {
    @apply mb-2;
  }
}
