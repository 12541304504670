.aside {
  @apply flex-1;
  @apply flex;
  @apply flex-col;

  @screen lg {
    &:not([class~='left-aside']) {
      @apply max-w-300;
    }

    &[class~='left-aside'] {
      @apply max-w-246;
    }
    &[class~='listing-left-aside'] {
      @apply max-w-[360px];
    }
  }

  flex: 1 1 100%;
  @apply w-full;
  @apply max-w-full;

  [class~='article-thumbnail'] {
    @apply px-0;
  }

  @media (max-width: 400px) {
    [class~='article-wrapper'][class~='compact'] {
      [class~='article-thumbnail'] {
        max-width: rem(350);
      }
    }
  }

  @media (min-width: 400px) {
    [class~='article-wrapper'][class~='compact'] {
      margin-left: rem(-6);
      margin-right: rem(-6);
      padding-left: rem(6);
      padding-right: rem(6);
      max-width: 18.75rem;
    }
  }
}

// template.scss
.main-wrapper {
  @apply flex;
  @apply flex-col;
  @apply flex-wrap;
  @apply justify-between;

  @screen lg {
    @apply flex-row;
  }

  .main-content {
    @apply max-w-full;
    @apply flex-1;
    @apply mb-8;
    @apply min-w-72;

    @screen lg {
      @apply mr-16;
      @apply max-w-[656px];
    }

    @screen xl {
      @apply max-w-[832px];
    }
  }
}

//style here can be overridden by styles/3-components/ads.scss
.bg-blue-dark-screen,
.bg-blue-light-screen,
.bg-blue-darkest-screen,
.bg-black-screen,
.bg-black-scr,
.bg-gray-100-screen,
.bg-gray-200-screen {
  @apply relative;

  &:after {
    @apply absolute;
    @apply top-0;
    @apply bottom-0;
    @apply w-screen;
    z-index: -1;
    content: '';
    left: calc(-50vw + 50%);
  }
}

.bg-black-scr,
.bg-black-screen {
  &:after {
    @apply bg-black;
  }
}

.bg-gray-100-screen {
  &:after {
    @apply bg-gray-100;
  }
}

.bg-gray-200-screen {
  &:after {
    @apply bg-gray-200;
  }
}

.bg-blue-light-screen {
  &:after {
    @apply bg-blue-light;
  }
}

.bg-blue-dark-screen {
  &:after {
    @apply bg-blue-dark;
  }
}

.bg-blue-darkest-screen {
  &:after {
    @apply bg-blue-darkest;
  }
}
