@use '/styles/mixin/fonts.module.scss' as *;

.wp-block-quote.is-style-drive-quote {
  @apply border-l-0;
  @apply m-0;
  @apply pl-0;
  @apply my-10;
  @apply mx-2;
  @apply bg-blue-50;
  @apply rounded-2xl;
  @apply px-8;
  @apply py-[35px];
  @screen md {
    @apply px-14;
    @apply mx-auto;
    @apply max-w-[703px];
  }
}

.is-style-drive-quote {
  p {
    @apply flex;
    @apply flex-col;
    @apply text-center;
    @apply text-black;
    @apply font-primary;
    @include font-heading('desktop', 'h3');
    @apply m-0;
    &::before {
      content: '“';
      @apply -mb-2;
      @include font-heading('desktop', 'h1');
      @apply leading-none;
      @apply text-blue-normal;
      padding-right: 1px;
    }
    &::after {
      content: '”';
      @apply mt-4;
      @apply -mb-6;
      @include font-heading('desktop', 'h1');
      @apply leading-none;
      @apply text-blue-normal;
      padding-left: 1px;
    }
  }
  > cite {
    display: none !important;
  }
}
