// Text Colors
.has-gray-2-color {
  color: $gray-2;
}

// Background colors.
.has-gray-1-background-color {
  @apply bg-gray-100;
}

.has-gray-3-background-color {
  @apply bg-gray-200;
}

.has-blue-dark-color {
  @apply text-blue-dark;
}
