.slick-arrow {
  &.slick-prev::before,
  &.slick-next::before {
    color: $gray-2;
  }
}

.slick-slider {
  .slick-slide {
    padding: 0 3px;

    @screen md {
      padding: 0 rem(10);
    }
  }

  .slick-list {
    margin: 0 -0.75rem;
  }
}
