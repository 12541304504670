.dropdown {
  @apply relative;
  @apply shadow;
  @apply w-full;
  @apply rounded-lg;
  @apply text-white;

  .heading {
    @apply border;
    @apply border-opacity-50;
    @apply flex;
    @apply relative;
    padding-top: rem(11);
    padding-bottom: rem(11);
    @apply pl-4;
    @apply pr-8;
    @apply rounded-lg;
    @apply cursor-pointer;

    &:hover {
      @apply border-opacity-75;
    }

    .arrow {
      @apply duration-75;
      @apply transition-colors;
      @apply duration-500;
      @apply ease-in-out;
      @apply pointer-events-none;
      @apply absolute;
      @apply inset-y-0;
      right: rem(10);
      @apply flex;
      @apply items-center;
      @apply rounded-tr-lg;
      @apply rounded-br-lg;
    }
  }

  &.black {
    .heading {
      @apply border-black;
      @apply bg-black;

      &:hover {
        @apply border-black;
      }
    }

    &.disabled {
      .heading:hover {
        @apply border-opacity-50;
      }
    }
  }

  &.white {
    @apply text-blue-dark;

    .heading {
      @apply bg-white;
      @apply border-white;
      @apply border-opacity-75;

      &:hover {
        @apply border-opacity-100;
      }
    }

    &.disabled {
      .heading:hover {
        @apply border-opacity-75;
      }
    }
  }

  &.secondary {
    @apply text-black;

    &.black {
      .heading {
        @apply border-black;
        @apply bg-transparent;

        &.open {
          @apply bg-black;
          @apply text-white;
        }

        &:hover {
          @apply border-black;
        }
      }

      &.disabled {
        .heading:hover {
          @apply border-opacity-50;
        }
      }
    }

    &.white {
      .heading {
        @apply bg-transparent;
        @apply text-white;
        @apply border-white;
        @apply border-opacity-75;

        &:hover {
          @apply border-opacity-100;
        }

        &.open {
          @apply border-blue-light;
          @apply border-opacity-75;
        }
      }

      &.disabled {
        .heading:hover {
          @apply border-opacity-75;
        }
      }
    }
  }

  &.no-border {
    @apply shadow-none;
    @apply text-gray-400;
    @apply rounded-none;

    .heading {
      @apply border-0;
      @apply bg-transparent;
      @apply p-0;

      .arrow {
        @apply pr-0;
      }
    }
  }

  .content {
    @apply border;
    @apply border-t-0;
    @apply border-gray-300;
    @apply pt-1;
    @apply z-30;
    @apply absolute;
    @apply right-0;
    @apply left-0;

    .items {
      @apply bg-white;
      @apply text-black;
      @apply overflow-scroll;
      max-height: rem(400);

      .seperator {
        @apply my-2;
        @apply mx-3;
        @apply w-11/12;
        @apply border-b;
        @apply border-gray-400;
      }

      .seperator-full {
        @apply my-2;
        width: 95%;
        @apply border-b;
        @apply border-gray-400;
      }

      .item {
        @apply cursor-pointer;

        .item:not(no-padding) {
          @apply p-3;
        }

        &:hover {
          @apply bg-gray-100;
        }

        &.no-padding {
          @apply p-0;
        }
      }
    }

    /* width */
    .items::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    .items::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    .items::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    .items::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    /* Hide horizontal scroll  */
    .items {
      overflow-x: hidden;
    }

    .actions {
      @apply flex;
      @apply items-center;
      @apply justify-between;
      @apply bg-gray-300;
      @apply px-3;
      @apply absolute;
      @apply bottom-0;
      @apply left-0;
      @apply right-0;
    }
  }

  &.disabled {
    @apply opacity-50;
    @apply border-opacity-50;

    .heading {
      @apply cursor-default;
    }
  }
}

/* width */
.custom-scroll::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.custom-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.custom-scroll::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Hide horizontal scroll  */
.custom-scroll {
  overflow-x: hidden;
}
