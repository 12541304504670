@use '/styles/mixin/fonts.module.scss' as *;

.drive-2x2block {
  @apply mt-0;
  @apply mb-8;

  h4 {
    @include font-heading('mobile', 'h4');
    @apply mt-6;
    @apply min-h-10;
    @apply mb-0;
    @apply w-full;
    @apply text-left;

    @screen md {
      @include font-heading('desktop', 'h3');
      @apply font-medium;
    }
    a {
      @include font-subtext('mobile', 'large');
      @apply text-black;

      @screen md {
        @include font-heading('desktop', 'h3');
      }
    }
  }

  p {
    @apply my-0;
    @apply text-left;
    @apply w-full;
    @include font-text('mobile', 'medium');

    @screen md {
      @apply mt-2;
      @include font-text('desktop', 'medium');
    }

    a {
      @include font-text('mobile', 'medium');
      @apply text-gray-400;

      @screen md {
        @include font-text('desktop', 'medium');
      }
    }
  }

  .wp-block-columns {
    @screen md {
      margin-bottom: 38px;
    }
  }

  .wp-block-column {
    @apply flex;
    @apply flex-wrap;
    @apply justify-center;
    @apply mt-6;

    @screen md {
      @apply mx-auto;
      @apply mt-0;
      @apply max-w-[806px];
    }
  }

  .wp-block-media-text {
    width: 100%;
    min-height: 272px;
    @apply p-0;
    @apply mb-6;
    @apply min-w-0;
    @apply max-w-none;
    @apply grid;
    @apply gap-4;
    grid-template-columns: auto !important;
    @screen md {
      @apply px-5;
      width: 50%;
      min-height: 380px;
      @apply block;

      &:nth-child(even) {
        @apply pl-2;
      }
      &:nth-child(odd) {
        @apply pr-2;
      }
      @apply p-0;
    }
  }

  .wp-block-media-text__content {
    @apply flex;
    @apply flex-col;
    @apply min-h-[144px];
    @apply pl-0 #{!important};

    @screen md {
      @apply px-0;
      @apply h-auto;
      @apply min-h-[184px];
    }
  }
}
