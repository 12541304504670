.btn {
  @apply flex;
  @apply justify-center;
  @apply items-center;
  @apply rounded-lg;
  @apply align-middle;
  @apply cursor-pointer;
  @apply px-6;
  @apply my-2;
  @apply py-3;
  @apply text-white;
  @apply border;
  @apply transition-all;
  @apply duration-500;
  @apply z-10;
  @apply relative;
  @apply min-h-12;

  &:not(.secondary) {
    &:before {
      border-radius: inherit;
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: -10;
      @apply transition-all;
      @apply duration-500;
      background-image: linear-gradient(to right, var(--gradient-color-stops)) !important;
      --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color),
        var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
    }
  }

  &:hover {
    &:before {
      @apply opacity-100;
    }
  }

  &.black {
    @apply bg-black;
    @apply border-black;

    &.no-gradiant {
      &:hover {
        @apply border-gray-500;
        @apply bg-gray-500;
        --gradient-from-color: #{$gray-500} !important;
        --gradient-via-color: #{$gray-500} !important;
        --gradient-to-color: #{$gray-500} !important;
      }
    }

    &:hover {
      --gradient-from-color: #{$black} !important;
      --gradient-via-color: #{$black} !important;
      --gradient-to-color: #{$gray-500} !important;
    }
  }

  &.gray {
    @apply text-black;
    @apply bg-gray-200;
    @apply border-gray-200;

    &:hover {
      --gradient-from-color: #{$gray-200} !important;
      --gradient-via-color: #{$gray-200} !important;
      --gradient-to-color: #{$gray-100} !important;
    }
  }

  &.white {
    @apply text-black;
    @apply bg-white;
    @apply border-white;

    &:hover {
      --gradient-from-color: #{$white} !important;
      --gradient-via-color: #{$white} !important;
      --gradient-to-color: #{$gray-100} !important;
    }
  }

  &.white-blue {
    @apply text-blue-dark;
    @apply bg-white;
    @apply border-white;

    &:hover {
      --gradient-from-color: #{$white} !important;
      --gradient-via-color: #{$white} !important;
      --gradient-to-color: #{$gray-100} !important;
    }
  }

  &.blue {
    @apply bg-blue-dark;
    @apply border-none;

    &:hover {
      &:not([disabled]) {
        @apply bg-blue-normal;
      }
    }
  }

  &.bluedarkest2 {
    @apply bg-blue-darkest2;
    @apply border-none;

    &:hover {
      &:not([disabled]) {
        @apply bg-blue-darker;
      }
    }
  }

  &.green {
    @apply bg-teal-dark;
    @apply border-teal-dark;

    &:hover {
      --gradient-from-color: #{$teal-dark} !important;
      --gradient-via-color: #{$teal-dark} !important;
      --gradient-to-color: #{$teal-normal} !important;
    }
  }

  &.red {
    @apply bg-coral-dark;
    @apply border-coral-dark;
  }

  &.secondary {
    @apply bg-transparent;
    border-style: solid !important;

    &:hover {
      --text-opacity: 1 !important;
      color: #fff !important;
      color: rgba(255, 255, 255, var(--text-opacity)) !important;
    }

    &.black {
      @apply border-black;
      @apply text-black;

      &:hover {
        @apply bg-black;
      }
    }

    &.white {
      @apply text-white;
      @apply border-white;

      &:hover {
        --text-opacity: 1 !important;
        color: #000 !important;
        color: rgba(0, 0, 0, var(--text-opacity)) !important;
        @apply bg-white;
      }
    }

    &.gray {
      @apply border-gray-100;

      &:hover {
        --text-opacity: 1 !important;
        color: #000 !important;
        color: rgba(0, 0, 0, var(--text-opacity)) !important;
        @apply bg-gray-100;
      }
    }

    &.blue {
      @apply border-blue-dark;
      @apply text-blue-dark;

      &:hover {
        --gradient-from-color: #{$blue-dark} !important;
        --gradient-via-color: #{$blue-dark} !important;

        &:not([disabled]) {
          --gradient-to-color: #{$blue-normal} !important;
        }
      }
    }

    &.green {
      @apply text-teal-dark;
      @apply border-teal-dark;

      &:hover {
        @apply bg-teal-dark;
      }
    }

    &.red {
      @apply text-coral-dark;
      @apply border-coral-dark;

      &:hover {
        @apply bg-coral-dark;
      }
    }
  }

  &.no-gradient:hover {
    --gradient-from-color: unset !important;
    --gradient-via-color: unset !important;
    --gradient-to-color: unset !important;
  }
}
