.slick-slider {
  &.with-links {
    .slick-slide {
      pointer-events: none;
      cursor: default;
      &.slick-active.slick-current {
        pointer-events: auto;
        cursor: pointer;
      }
    }
  }
  .slick-slide {
    * {
      outline: none;

      &:focus {
        outline: none;
      }
    }

    figure {
      display: block !important;
      img:not(.drive-icon) {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
      }
    }
  }

  .slick-arrow {
    @apply w-0;
    @apply h-0;
    @apply transition-all;
    @apply duration-500;
    @apply z-10;
    width: rem(34);
    height: rem(60);

    &:before {
      border: solid white;
      border-width: 0 rem(2) rem(2) 0;
      display: inline-block;
      z-index: 99;
      color: transparent !important;
      font-family: inherit;
      position: absolute;
      box-shadow: rem(3) rem(3) rem(3) 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.06);
      padding: rem(5);

      @screen lg {
        padding: rem(9);
      }
    }

    &.slick-prev {
      left: rem(20);

      &:before {
        left: rem(-10);
        transform: rotate(135deg) translateX(-75%);
        -webkit-transform: rotate(135deg) translateX(-75%);
      }
    }

    &.slick-next {
      right: rem(20);

      &:before {
        right: rem(-10);
        transform: rotate(-45deg) translateY(-75%);
        -webkit-transform: rotate(-45deg) translateY(-75%);
      }
    }
  }

  .slick-dots {
    bottom: 3rem;
    @apply w-4/5;

    @screen md {
      @apply w-2/3;
    }

    @apply mx-auto;
    @apply max-h-6;
    @apply left-0;
    @apply right-0;
    @apply overflow-hidden;
    @apply text-white;

    li {
      width: auto;
      height: auto;
      @apply mx-0.5;
      @screen sm {
        margin-left: 5px;
        margin-right: 5px;
      }
    }

    button {
      @apply w-8;
      @apply p-0;
      height: rem(4);
      @apply bg-gray-400;
      @apply mx-0.5;

      @screen sm {
        @apply mx-1;
      }

      &:before {
        content: '';
        @apply h-0;
        @apply w-0;
      }
    }

    .slick-active {
      button {
        @apply bg-white;
      }
    }
  }

  &.arrows-bottom {
    @apply mb-8;

    .slick-arrow {
      height: 1.5rem;
      width: 1.5rem;

      &:before {
        box-shadow: none;
        border-color: black;
        padding: rem(4);
      }

      &.slick-prev {
        left: rem(-3);
        bottom: 0;
        transform: translateY(2.5rem);
        top: unset;

        &:before {
          left: 0;
          width: rem(10);
          height: rem(10);
        }
      }

      &.slick-next {
        right: rem(-3);
        bottom: 0;
        transform: translateY(2.5rem);
        top: unset;

        &:before {
          right: 0;
          width: rem(10);
          height: rem(10);
        }
      }
    }

    .slick-dots {
      bottom: rem(-3);
      transform: translateY(2rem);

      button {
        @apply bg-gray-400;
      }

      .slick-active {
        button {
          @apply bg-black;
        }
      }
    }
  }

  &.arrows-side,
  &.arrows-bottom-2 {
    .slick-arrow {
      height: 1.5rem;
      width: 1.5rem;

      &:before {
        border-color: black;
        padding: rem(6);
      }

      &.slick-prev {
        &:before {
          left: 0;
          width: 0.5rem;
          height: 1rem;
        }
      }

      &.slick-next {
        &:before {
          right: 0;
          width: 0.5rem;
          height: 1rem;
        }
      }
    }

    .slick-dots {
      button {
        @apply bg-gray-400;
      }

      .slick-active {
        button {
          @apply bg-black;
        }
      }
    }

    &.arrows-bottom-2 {
      @apply mb-8;

      .slick-arrow {
        &.slick-next,
        &.slick-prev {
          bottom: 0;
          top: unset;
          transform: translateY(2.5rem);
        }

        &.slick-next {
          right: rem(-3);
        }
      }

      .slick-dots {
        bottom: 0;
        transform: translateY(2rem);
      }
    }

    &.arrows-side {
      .slick-arrow {
        &.slick-prev {
          left: -2.5rem;
        }

        &.slick-next {
          right: -2.5rem;
        }
      }
    }

    .slick-slide {
      padding: 0;
      @apply flex;
      @apply justify-center;
      @apply items-center;
    }
  }

  &.arrows-center {
    @apply mb-8;

    .slick-arrow {
      &:before {
        border-color: black;
        padding: rem(6);
      }

      &.slick-prev {
        &:before {
          width: 0.5rem;
          height: 1rem;
        }
      }

      &.slick-next {
        &:before {
          width: 0.5rem;
          height: 1rem;
        }
      }

      &.slick-next,
      &.slick-prev {
        bottom: 0;
        top: 5rem;
        transform: translateY(2.5rem);
      }
    }

    .slick-slide {
      padding: 0;
      @apply flex;
      @apply justify-center;
      @apply items-center;
    }
  }

  &.arrows-center {
    @apply mb-8;

    .slick-arrow {
      &:before {
        border-color: black;
        padding: rem(6);
      }

      &.slick-prev {
        &:before {
          width: 0.5rem;
          height: 1rem;
        }
      }

      &.slick-next {
        &:before {
          width: 0.5rem;
          height: 1rem;
        }
      }

      &.slick-next,
      &.slick-prev {
        bottom: 0;
        top: 5rem;
        transform: translateY(2.5rem);
      }
    }

    .slick-slide {
      padding: 0;
      @apply flex;
      @apply justify-center;
      @apply items-center;
    }
  }

  &.arrows-2-slick-list {
    .slick-list {
      margin-left: 1.25rem;
      margin-right: 1.25rem;

      .slick-track {
        width: auto !important;
      }
    }
  }

  &.arrows-center-2 {
    @apply mb-8;

    .slick-arrow {
      &:before {
        border-color: black;
        padding: rem(6);
      }

      &.slick-prev {
        left: unset;
        width: 0.5rem;

        &:before {
          left: unset;
          width: 0.5rem;
          height: 1rem;
        }
      }

      &.slick-next {
        width: 0.5rem;

        &:before {
          right: unset;
          width: 0.5rem;
          height: 1rem;
        }
      }
    }

    .slick-slide {
      @apply p-0;
      @apply flex;
      @apply justify-center;
      @apply items-center;
    }
  }

  &.vertical {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin: 0 10px;
    max-height: 100%;

    @screen md {
      margin: 0 0;
    }

    .slick-arrow {
      &.slick-next,
      &.slick-prev {
        left: 0;
        right: 0;
        transform: rotate(90deg);
        width: 1.5rem;
        height: 1.5rem;
        margin: auto;

        &::before {
          font-size: rem(12);
          padding: 0 !important;
          border-width: 0 rem(2) rem(2) 0 !important;
        }
      }

      &.slick-prev {
        top: 0 !important;
        bottom: unset !important;
        padding-left: 35px;
      }

      &.slick-next {
        bottom: rem(6) !important;
        top: unset !important;
        padding-right: 35px;
      }
    }

    .slick-list {
      max-height: 100%;
      .slick-track {
        max-height: 100%;
      }
    }

    .slick-slider {
      .slick-list {
        margin: 0 0;
        @screen md {
          margin: 0 -0.75rem;
        }
      }
    }
  }

  &[class~='articles_drive-articles-slider--navigation-bottom'] {
    .slick-list {
      @apply mb-5;
      //no navigation, take over space with more margin
      &:last-child {
        @apply mb-10;
      }
    }

    .slick-dots {
      @apply flex #{!important};
      @apply items-center;
      @apply justify-center;
      @apply transform-none;
      @apply mb-5;
      @apply static;
    }
    .slick-arrow {
      @apply translate-y-1/2 #{!important};
    }
  }
}

.slick-slider {
  &.arrows-bottom-2 {
    .slick-arrow {
      &.slick-prev {
        @media (min-width: 360px) {
          left: 0;
        }
      }
    }
  }
}
